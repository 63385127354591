<template>
  <div class="default-layout">
    <network-status v-if="$platform === 'electron'" />
    <sync v-if="$platform === 'electron' && type === 'standalone'" />
    <navbar fapp="regional" />
    <div class="d-flex">
      <sidebar />
      <div
        class="content bg-regional"
        :style="
          'background-image: url(' +
            $assetsBase +
            'images/icons/fapps/regional.svg);'
        "
      >
        <breadcrumbs
          v-if="
            !$route.name.startsWith('index__') &&
              !$route.name.startsWith('fitness__') &&
              !$route.name.startsWith('regional__')
          "
        />
        <nuxt />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Navbar from '~/components/navbar.vue'
import Breadcrumbs from '~/components/regional/breadcrumbs'
import Sidebar from '~/components/regional/sidebar'
import NetworkStatus from '~/components/networkStatus'
import Sync from '~/components/sync'

export default {
  components: {
    Breadcrumbs,
    Navbar,
    Sidebar,
    NetworkStatus,
    Sync
  },
  middleware: ['router', 'auth', 'regional'],
  computed: {
    ...mapGetters({
      user: 'user/user',
      settings: 'user/settings',
      applications: 'user/applications',
      type: 'config/type',
      onLine: 'network/onLine'
    })
  },
  watch: {
    onLine() {
      this.$router.push(this.localePath({ name: 'index' }))
    }
  },
  async created() {
    if (!this.settings) {
      await this.getSettings()
    }

    if (
      this.$platform === 'electron' &&
      (this.settings.locale === 'nl_BE'
        ? 'nl-be'
        : this.settings.locale.slice(0, 2)) !== this.$i18n.locale
    ) {
      this.$router.push(
        this.switchLocalePath(
          this.settings.locale === 'nl_BE'
            ? 'nl-be'
            : this.settings.locale.slice(0, 2)
        )
      )
    }

    const locale = this.user ? this.user.locale.slice(0, 2) : 'en'
    this.$dayjs.locale(locale)
  },
  updated() {
    this.$dayjs.locale(this.$i18n.locale)
  },
  methods: {
    ...mapActions({
      getSettings: 'user/getSettings'
    })
  }
}
</script>

<style lang="scss">
.bg-regional {
  background-repeat: no-repeat;
  background-size: 500px;
  background-position: 97% 32px;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"deviceModal",attrs:{"id":"device-modal","size":"sm","title":_vm.$t('regional.exam.connectDevice'),"header-text-variant":"light","header-class":"border-bottom-0","header-bg-variant":"primary","footer-class":"border-top-0 pt-0","centered":"","no-close-on-backdrop":""}},[_c('div',[_c('div',{staticClass:"text-center",staticStyle:{"font-size":"1rem","min-height":"106px"}},[(_vm.spinnerEnabled)?_c('div',{staticClass:"d-flex justify-content-center align-items-center",staticStyle:{"min-height":"106px"}},[_c('b-spinner',{attrs:{"variant":"primary","label":_vm.$t('app.common.loading')}})],1):_vm._e(),_vm._v(" "),(
          !_vm.spinnerEnabled &&
            _vm.response &&
            _vm.response.result &&
            _vm.response.result.body
        )?_c('div',{domProps:{"innerHTML":_vm._s(_vm.response.result.body)}}):_vm._e(),_vm._v(" "),(
          !_vm.spinnerEnabled && _vm.error && _vm.error.result && _vm.error.result.message
        )?_c('div',{staticClass:"text-danger",domProps:{"innerHTML":_vm._s(_vm.error.result.message)}}):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"w-100",attrs:{"slot":"modal-footer"},slot:"modal-footer"},[_c('div',{staticClass:"float-right"},[_c('b-button',{attrs:{"disabled":_vm.spinnerEnabled,"variant":"outline-secondary"},on:{"click":_vm.closeModal}},[_vm._v("\n        "+_vm._s(_vm.$t('fitness.modal.close'))+"\n      ")]),_vm._v(" "),_c('b-button',{staticClass:"ml-1",attrs:{"disabled":_vm.spinnerEnabled || !_vm.response,"variant":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("\n        "+_vm._s(_vm.$t('app.forms.insert'))+"\n      ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-navbar toggleable="lg" type="dark" variant="light-blue" sticky>
    <b-container>
      <b-navbar-brand>
        <component :is="type === 'shared' ? 'hboLogo' : 'logo'" />

        <div
          v-if="$platform === 'electron' && !onLine && type !== 'shared'"
          class="offline-label"
        >
          offline
        </div>
      </b-navbar-brand>
      <b-navbar-nav class="ml-auto">
        <b-nav-item
          v-if="$platform === 'electron' && type === 'shared'"
          :to="localePath('/config')"
        >
          {{ $t('app.config.title') }}
        </b-nav-item>

        <img
          :src="`${$assetsBase}images/akern-logo.svg`"
          width="128"
          height="48"
          class="ml-4"
          alt="Akern"
        />
      </b-navbar-nav>
    </b-container>
  </b-navbar>
</template>

<script>
import { mapGetters } from 'vuex'
import logo from '~/components/logo.vue'
import hboLogo from '~/components/hboLogo.vue'

export default {
  components: {
    logo, // eslint-disable-line vue/no-unused-components
    hboLogo // eslint-disable-line vue/no-unused-components
  },
  computed: {
    ...mapGetters({
      type: 'config/type',
      onLine: 'network/onLine'
    })
  }
}
</script>

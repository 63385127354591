const state = () => ({
  colors: {
    green: '#62ae52',
    darkGreen: '#057677',
    red: '#d8413e',
    purple: '#955296',
    pink: '#dbbadd',
    blue: '#3774ab',
    lightBlue: '#27bad9',
    'light-blue': '#27bad9',
    darkBlue: '#008fab',
    brightBlue: '#007bff',
    gray: '#e5e5e5',
    lightGray: '#f8f9fa',
    darkGray: '#cccccc',
    yellow: '#f0ac4a',
    orange: '#ef7b0b',
    lightOrange: '#f88349'
  },
  formatForDayjs: {
    en: 'MM/DD/YYYY',
    cy: 'MM/DD/YYYY',
    it: 'DD/MM/YYYY',
    de: 'DD.MM.YYYY',
    nl: 'DD-MM-YYYY',
    'nl-be': 'DD/MM/YYYY',
    es: 'DD/MM/YYYY',
    pl: 'YYYY-MM-DD',
    zh: 'YYYY-MM-DD',
    fr: 'DD.MM.YYYY'
  },
  formatForLabels: {
    en: '{value:%m/%d/%Y}',
    cy: '{value:%m/%d/%Y}',
    it: '{value:%d/%m/%Y}',
    de: '{value:%d.%m.%Y}',
    nl: '{value:%d-%m-%Y}',
    'nl-be': '{value:%d/%m/%Y}',
    es: '{value:%d/%m/%Y}',
    pl: '{value:%Y-%m-%d}',
    zh: '{value:%Y-%m-%d}',
    fr: '{value:%d.%m.%Y}'
  }
})

const getters = {
  colors: state => state.colors,
  formatForDayjs: state => state.formatForDayjs,
  formatForLabels: state => state.formatForLabels
}

export default {
  state,
  getters,
  namespaced: true
}

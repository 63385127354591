const state = () => ({
  list: null
})

const getters = {
  list: state => state.list,
  bodygram: state => {
    return state.list && state.list.find(app => app.code === 'BOD')
  },
  fitness: state => {
    return state.list && state.list.find(app => app.code === 'FIT')
  },
  regional: state => {
    return state.list && state.list.find(app => app.code === 'REG')
  },
  keto: state => {
    return state.list.find(app => app.code === 'KET')
  },
  isBodyscanLicensed: state => {
    const bodyscanApplication =
      state.list && state.list.find(app => app.code === 'BDS')

    if (bodyscanApplication) {
      return bodyscanApplication.licensed
    }

    return false
  },
  isSizeYouLicensed: state => {
    const bodyscanApplication =
      state.list && state.list.find(app => app.code === 'SZU')

    if (bodyscanApplication) {
      return bodyscanApplication.licensed
    }

    return false
  }
}

const mutations = {
  receiveApplications(state, applications) {
    state.list = applications
  }
}

const actions = {
  getApplications({ rootGetters, commit }) {
    return this.$axios
      .post('/akern-platform-backend/v1/customer/licenses/applications', {
        accessToken: rootGetters['user/token']
      })
      .then(({ data }) => {
        commit('receiveApplications', data.applications)
      })
      .catch(error => console.error(error))
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}

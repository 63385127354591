var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-navbar',{class:_vm.fapp + ' with-sidebar',attrs:{"toggleable":"lg","type":"dark","variant":"light-blue","sticky":""}},[_c('b-container',{staticClass:"p-0",attrs:{"fluid":""}},[(_vm.type !== 'shared' || (_vm.type === 'shared' && _vm.applications))?_c('b-navbar-brand',{attrs:{"to":_vm.localePath(("" + (_vm.fapp ? _vm.fapp : 'index')))}},[_c(_vm.logoComponent,{tag:"component"}),_vm._v(" "),(_vm.$platform === 'electron' && !_vm.onLine && _vm.type !== 'shared')?_c('div',{staticClass:"offline-label"},[_vm._v("\n        offline\n      ")]):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.type === 'shared' && !_vm.applications)?_c('b-navbar-brand',[_c(_vm.logoComponent,{tag:"component"})],1):_vm._e(),_vm._v(" "),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_vm._v(" "),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"ml-auto"},[(_vm.user && _vm.user.customer && _vm.user.customer.country === 'it')?_c('nuxt-link',{staticClass:"mr-1",attrs:{"to":_vm.localePath('settings-documents')}},[_c('img',{attrs:{"height":"52px","alt":"documenti","src":(_vm.$assetsBase + "images/icons/documenti-" + (_vm.isButtonPath(
                'documents'
              )) + "@2x.png")}})]):_vm._e(),_vm._v(" "),(_vm.user && _vm.user.customer && _vm.user.customer.country === 'it')?_c('a',{staticClass:"mr-1",attrs:{"href":_vm.shopLink ? _vm.shopLink.url : '',"disabled":!_vm.shopLink,"target":"_blank"}},[_c('img',{attrs:{"height":"52px","alt":"shop","src":(_vm.$assetsBase + "images/icons/shop-" + (_vm.isButtonPath('shop')) + "@2x.png")}})]):_vm._e(),_vm._v(" "),(_vm.user && _vm.user.customer && _vm.user.customer.country === 'it')?_c('nuxt-link',{staticClass:"mr-1",attrs:{"to":_vm.localePath('academy')}},[_c('img',{attrs:{"height":"52px","alt":"academy","src":(_vm.$assetsBase + "images/icons/academy-" + (_vm.isButtonPath(
                'academy'
              )) + "@2x.png")}})]):_vm._e(),_vm._v(" "),_c('b-nav-form',{staticClass:"search-form",on:{"submit":function($event){$event.preventDefault();return _vm.search.apply(null, arguments)}}},[_c('b-form-input',{staticClass:"mr-sm-2 input-transparent",staticStyle:{"width":"220px"},attrs:{"size":"lg","id":"search-input","placeholder":_vm.$t('app.common.search'),"state":!!_vm.searchQuery && _vm.searchQuery.length < 3 ? false : null},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_vm._v(" "),(_vm.searchQuery && _vm.searchQuery.length > 2)?_c('i',{staticClass:"fa fa-times clear-search",on:{"click":function($event){_vm.searchQuery = ''}}}):_vm._e(),_vm._v(" "),_c('b-tooltip',{attrs:{"show":!!_vm.searchQuery && _vm.searchQuery.length < 3,"variant":"danger","target":"search-input","placement":"bottom","triggers":""}},[_vm._v("\n            "+_vm._s(_vm.$t('app.common.searchLimit'))+"\n          ")])],1),_vm._v(" "),(_vm.user)?_c('b-nav-item-dropdown',{attrs:{"right":""}},[_c('template',{slot:"button-content"},[_vm._v("\n            "+_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName)+"\n          ")]),_vm._v(" "),(
              (_vm.type !== 'standalone' && _vm.type !== 'shared') ||
                (_vm.type !== 'standalone' && _vm.applications) ||
                (_vm.type === 'standalone' && _vm.onLine)
            )?_c('b-dropdown-item',{attrs:{"to":_vm.localePath('settings')}},[_vm._v("\n            "+_vm._s(_vm.$t('app.settings.settings'))+"\n          ")]):_vm._e(),_vm._v(" "),(_vm.forcedOffline)?_c('b-dropdown-item',{on:{"click":_vm.setOnline}},[_vm._v("\n            "+_vm._s(_vm.$t('app.offline.button.accessOnline'))+"\n          ")]):_vm._e(),_vm._v(" "),(_vm.$platform === 'electron' && _vm.onLine)?_c('b-dropdown-item',{on:{"click":_vm.showModalToSelectSyncMethod}},[_vm._v("\n            "+_vm._s(_vm.$t('app.offline.button.sync'))+"\n          ")]):_vm._e(),_vm._v(" "),(_vm.$platform === 'electron' && _vm.onLine)?_c('b-dropdown-item',{on:{"click":_vm.checkForUpdates}},[_vm._v("\n            "+_vm._s(_vm.$t('app.settings.checkForUpdates'))+"\n          ")]):_vm._e(),_vm._v(" "),(_vm.type === 'shared' && _vm.applications)?_c('b-dropdown-item',{on:{"click":_vm.changePassword}},[_vm._v("\n            "+_vm._s(_vm.$t('app.settings.changePassword'))+"\n          ")]):_vm._e(),_vm._v(" "),(_vm.$platform === 'electron' && _vm.type === 'shared')?_c('b-dropdown-item',{attrs:{"to":_vm.localePath('/config')}},[_vm._v("\n            "+_vm._s(_vm.$t('app.config.title'))+"\n          ")]):_vm._e(),_vm._v(" "),(
              _vm.$platform === 'electron' &&
                _vm.type === 'shared' &&
                _vm.applications &&
                _vm.user &&
                _vm.user.role === 'admin'
            )?_c('b-dropdown-item',{on:{"click":_vm.moveData}},[_vm._v("\n            "+_vm._s(_vm.$t('app.config.moveData'))+"\n          ")]):_vm._e(),_vm._v(" "),(
              _vm.$platform === 'electron' &&
                _vm.type === 'shared' &&
                _vm.applications &&
                _vm.user &&
                _vm.user.role === 'admin'
            )?_c('b-dropdown-item',{attrs:{"to":_vm.localePath('/users')}},[_vm._v("\n            "+_vm._s(_vm.$t('app.users.title'))+"\n          ")]):_vm._e(),_vm._v(" "),(_vm.onLine)?_c('b-dropdown-divider'):_vm._e(),_vm._v(" "),(_vm.type !== 'shared' || (_vm.type === 'shared' && _vm.applications))?_c('b-dropdown-item',{on:{"click":_vm.logout}},[_vm._v("\n            "+_vm._s(_vm.$t('app.common.signOut'))+"\n          ")]):_vm._e()],2):_vm._e(),_vm._v(" "),_c('img',{staticClass:"ml-4",attrs:{"src":(_vm.$assetsBase + "images/akern-logo.svg"),"width":"128","alt":"Akern"}}),_vm._v(" "),(typeof _vm.needToAcceptForApp === 'string')?_c('div',{staticClass:"card bg-danger text-white ml-4"},[_c('div',{staticClass:"card-body",staticStyle:{"padding-top":"0.75rem","padding-bottom":"0.75rem"}},[_c('i',{staticClass:"fas fa-exclamation-circle mr-1"}),_vm._v(" "),_c('nuxt-link',{staticClass:"text-white font-weight-bold",attrs:{"to":_vm.localePath(("/accept?app=" + _vm.needToAcceptForApp))}},[_vm._v("\n              "+_vm._s(_vm.$t('app.gdpr.acceptGdpr'))+"\n            ")])],1)]):_vm._e()],1)],1)],1),_vm._v(" "),_c('ChangePasswordModal',{ref:"change-password-modal",attrs:{"superLog":_vm.user ? _vm.user.superLog : false}}),_vm._v(" "),_c('ModalForSelectingSyncMethod',{ref:"modal-for-selecting-sync-method"}),_vm._v(" "),_c('MoveDataModal',{ref:"move-data-modal",on:{"done":_vm.onDataMoved}}),_vm._v(" "),_c('LicenseExpiredAdviseModal',{ref:"license-expired-modal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-navbar
    :class="fapp + ' with-sidebar'"
    toggleable="lg"
    type="dark"
    variant="light-blue"
    sticky
  >
    <b-container class="p-0" fluid>
      <b-navbar-brand
        v-if="type !== 'shared' || (type === 'shared' && applications)"
        :to="localePath(`${fapp ? fapp : 'index'}`)"
      >
        <!-- eslint-disable-next-line -->
        <component :is="logoComponent" />

        <div
          v-if="$platform === 'electron' && !onLine && type !== 'shared'"
          class="offline-label"
        >
          offline
        </div>
      </b-navbar-brand>

      <b-navbar-brand v-if="type === 'shared' && !applications">
        <!-- eslint-disable-next-line -->
        <component :is="logoComponent" />
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse" />

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <nuxt-link
            v-if="user && user.customer && user.customer.country === 'it'"
            :to="localePath('settings-documents')"
            class="mr-1"
          >
            <img
              height="52px"
              alt="documenti"
              :src="
                `${$assetsBase}images/icons/documenti-${isButtonPath(
                  'documents'
                )}@2x.png`
              "
            />
          </nuxt-link>
          <a
            v-if="user && user.customer && user.customer.country === 'it'"
            :href="shopLink ? shopLink.url : ''"
            :disabled="!shopLink"
            class="mr-1"
            target="_blank"
          >
            <img
              height="52px"
              alt="shop"
              :src="
                `${$assetsBase}images/icons/shop-${isButtonPath('shop')}@2x.png`
              "
            />
          </a>
          <nuxt-link
            v-if="user && user.customer && user.customer.country === 'it'"
            class="mr-1"
            :to="localePath('academy')"
          >
            <img
              height="52px"
              alt="academy"
              :src="
                `${$assetsBase}images/icons/academy-${isButtonPath(
                  'academy'
                )}@2x.png`
              "
            />
          </nuxt-link>
          <b-nav-form class="search-form" @submit.prevent="search">
            <b-form-input
              v-model="searchQuery"
              size="lg"
              id="search-input"
              class="mr-sm-2 input-transparent"
              style="width: 220px;"
              :placeholder="$t('app.common.search')"
              :state="!!searchQuery && searchQuery.length < 3 ? false : null"
            />
            <i
              v-if="searchQuery && searchQuery.length > 2"
              class="fa fa-times clear-search"
              @click="searchQuery = ''"
            />
            <b-tooltip
              :show="!!searchQuery && searchQuery.length < 3"
              variant="danger"
              target="search-input"
              placement="bottom"
              triggers=""
            >
              {{ $t('app.common.searchLimit') }}
            </b-tooltip>
          </b-nav-form>

          <b-nav-item-dropdown v-if="user" right>
            <!-- Using 'button-content' slot -->
            <template slot="button-content">
              {{ user.firstName }} {{ user.lastName }}
            </template>
            <b-dropdown-item
              v-if="
                (type !== 'standalone' && type !== 'shared') ||
                  (type !== 'standalone' && applications) ||
                  (type === 'standalone' && onLine)
              "
              :to="localePath('settings')"
            >
              {{ $t('app.settings.settings') }}
            </b-dropdown-item>
            <b-dropdown-item v-if="forcedOffline" @click="setOnline">
              {{ $t('app.offline.button.accessOnline') }}
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$platform === 'electron' && onLine"
              @click="showModalToSelectSyncMethod"
            >
              {{ $t('app.offline.button.sync') }}
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$platform === 'electron' && onLine"
              @click="checkForUpdates"
            >
              {{ $t('app.settings.checkForUpdates') }}
            </b-dropdown-item>
            <b-dropdown-item
              v-if="type === 'shared' && applications"
              @click="changePassword"
            >
              {{ $t('app.settings.changePassword') }}
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$platform === 'electron' && type === 'shared'"
              :to="localePath('/config')"
            >
              {{ $t('app.config.title') }}
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                $platform === 'electron' &&
                  type === 'shared' &&
                  applications &&
                  user &&
                  user.role === 'admin'
              "
              @click="moveData"
            >
              {{ $t('app.config.moveData') }}
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                $platform === 'electron' &&
                  type === 'shared' &&
                  applications &&
                  user &&
                  user.role === 'admin'
              "
              :to="localePath('/users')"
            >
              {{ $t('app.users.title') }}
            </b-dropdown-item>
            <b-dropdown-divider v-if="onLine" />
            <b-dropdown-item
              v-if="type !== 'shared' || (type === 'shared' && applications)"
              @click="logout"
            >
              {{ $t('app.common.signOut') }}
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <img
            :src="`${$assetsBase}images/akern-logo.svg`"
            width="128"
            class="ml-4"
            alt="Akern"
          />
          <div
            v-if="typeof needToAcceptForApp === 'string'"
            class="card bg-danger text-white ml-4"
          >
            <div
              class="card-body"
              style="padding-top: 0.75rem; padding-bottom: 0.75rem;"
            >
              <i class="fas fa-exclamation-circle mr-1" />
              <nuxt-link
                :to="localePath(`/accept?app=${needToAcceptForApp}`)"
                class="text-white font-weight-bold"
              >
                {{ $t('app.gdpr.acceptGdpr') }}
              </nuxt-link>
            </div>
          </div>
        </b-navbar-nav>
      </b-collapse>
    </b-container>

    <ChangePasswordModal
      ref="change-password-modal"
      :superLog="user ? user.superLog : false"
    />
    <ModalForSelectingSyncMethod ref="modal-for-selecting-sync-method" />
    <MoveDataModal ref="move-data-modal" @done="onDataMoved" />
    <LicenseExpiredAdviseModal ref="license-expired-modal" />
  </b-navbar>
</template>

<script>
/* eslint vue/no-unused-components: 0 */
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ChangePasswordModal from '../components/settings/ChangePasswordModal'
import ModalForSelectingSyncMethod from '../components/ModalForSelectingSyncMethod'
import MoveDataModal from '../components/MoveDataModal'
import LicenseExpiredAdviseModal from '../components/modals/LicenseExpiredAdviseModal'
import bodygramLogo from '~/components/logo.vue'
import hboLogo from '~/components/hboLogo.vue'
import fitnessLogo from '~/components/fitness/logo.vue'
import regionalLogo from '~/components/regional/logo.vue'
import ketoLogo from '~/components/keto/logo.vue'
import pentaLogo from '~/components/keto/pentaLogo.vue'

export default {
  components: {
    bodygramLogo,
    hboLogo,
    fitnessLogo,
    regionalLogo,
    ChangePasswordModal,
    ketoLogo,
    pentaLogo,
    ModalForSelectingSyncMethod,
    MoveDataModal,
    LicenseExpiredAdviseModal
  },
  props: {
    fapp: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      searchQuery: ''
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      onLine: 'network/onLine',
      forcedOffline: 'network/forcedOffline',
      settings: 'user/settings',
      type: 'config/type',
      bodygramApplication: 'applications/bodygram',
      fitnessApplication: 'applications/fitness',
      regionalApplication: 'applications/regional',
      shopLink: 'dashboard/shopLink',
      applications: 'applications/list'
    }),
    availableLocales() {
      return this.$i18n.locales
    },
    logoComponent() {
      if (this.fapp) {
        return this.fapp === 'keto' &&
          this.applications.find(x => x.code === 'KETP_TMP').licensed
          ? 'pentaLogo'
          : this.fapp + 'Logo'
      }

      if (this.type === 'shared') {
        return 'hboLogo'
      }

      return 'bodygramLogo'
    },
    needToAcceptForApp() {
      if (this.$route.name.startsWith('fitness')) {
        if (
          this.fitnessApplication &&
          this.fitnessApplication.signature &&
          this.fitnessApplication.signature.signatureRequired
        ) {
          return 'FIT'
        }
      } else if (this.$route.name.startsWith('regional')) {
        if (
          this.regionalApplication &&
          this.regionalApplication.signature &&
          this.regionalApplication.signature.signatureRequired
        ) {
          return 'REG'
        }
      } else if (
        this.bodygramApplication &&
        this.bodygramApplication.signature &&
        this.bodygramApplication.signature.signatureRequired
      ) {
        return 'BOD'
      }

      return null
    }
  },
  mounted() {
    this.getDashboard()
    this.handleExpiredLicense()
  },
  methods: {
    ...mapActions({
      logout: 'user/logout',
      searchPatients: 'patients/searchPatients',
      getDashboard: 'dashboard/getDashboard'
    }),
    ...mapMutations({
      setStatus: 'network/setStatus'
    }),
    setOnline() {
      this.setStatus(true)
      this.$router.push(
        this.localePath({
          name: 'index'
        })
      )
    },
    isButtonPath(button) {
      return this.$route.name.includes(button) ? 'ciano' : 'bianco'
    },
    showModalToSelectSyncMethod() {
      this.$refs['modal-for-selecting-sync-method'].showModal()
    },
    search() {
      if (this.searchQuery && this.searchQuery.length > 2) {
        if (this.$route.name.includes('search')) {
          this.searchPatients(this.searchQuery)
        }
        this.$router.push({
          path: this.localePath('search'),
          query: { q: this.searchQuery }
        })
      }
    },
    checkForUpdates() {
      this.$ipc('checkForUpdates', {
        silent: false,
        locale: this.$i18n.localeProperties.code
      }).then(result => {
        console.log(result)
      })
    },
    changePassword() {
      this.$refs['change-password-modal'].showModal()
    },
    moveData() {
      this.$refs['move-data-modal'].showModal()
    },
    handleExpiredLicense() {
      if (this.type === 'shared') {
        this.$refs['license-expired-modal'].showModal()
      }
    },
    onDataMoved() {
      window.location.reload()
    }
  }
}
</script>

const state = () => ({
  nutrigram: null,
  hydragram: null,
  ffmi: null,
  fmi: null,
  percentileExams: null,
  percentileEstimates: null,
  heightPercentiles: null,
  bmiPercentiles: null,
  weightPercentiles: null,
  nutriExam: null
})
const getters = {
  nutrigram: state => state.nutrigram,
  hydragram: state => state.hydragram,
  ffmi: state => state.ffmi,
  fmi: state => state.fmi,
  percentileExams: state => state.percentileExams,
  percentileEstimates: state => state.percentileEstimates,
  heightPercentiles: state => state.heightPercentiles,
  bmiPercentiles: state => state.bmiPercentiles,
  weightPercentiles: state => state.weightPercentiles,
  nutriExam: state => state.nutriExam
}
const mutations = {
  receiveNutrigram(state, nutrigram) {
    state.nutrigram = nutrigram
  },
  receiveNutriExam(state, exam) {
    state.nutriExam = exam
  },
  receiveHydragram(state, hydragram) {
    state.hydragram = hydragram
  },
  receiveFfmi(state, ffmi) {
    state.ffmi = ffmi
  },
  receiveFmi(state, fmi) {
    state.fmi = fmi
  },
  receivePercentileExams(state, exams) {
    state.percentileExams = exams
  },
  receivePercentileEstimates(state, estimates) {
    state.percentileEstimates = estimates
  },
  receiveHeightPercentiles(state, height) {
    state.heightPercentiles = height
  },
  receiveBmiPercentiles(state, bmi) {
    state.bmiPercentiles = bmi
  },
  receiveWeightPercentiles(state, weight) {
    state.weightPercentiles = weight
  }
}
const actions = {
  getNutrigram({ commit, rootState }, examId) {
    return this.$axios
      .post('/bodygram-backend/v1/app/functions/nutrigram', {
        accessToken: rootState.user.token,
        examId: examId
      })
      .then(response => {
        commit('receiveNutrigram', response.data.scale)
        commit('exams/receiveBaseline', response.data.patient.baseline, {
          root: true
        })
      })
  },
  getChineseNutri({ commit, rootState }, examId) {
    return this.$axios
      .post('/bodygram-backend/v1/app/functions/nutrigram', {
        accessToken: rootState.user.token,
        examId: examId
      })
      .then(response => {
        commit('receiveNutrigram', response.data.scale)
        commit('receiveNutriExam', response.data.exam)
        commit('exams/receiveBaseline', response.data.patient.baseline, {
          root: true
        })
      })
  },
  getHydragram({ commit, rootState }, examId) {
    return this.$axios
      .post('/bodygram-backend/v1/app/functions/hydragram', {
        accessToken: rootState.user.token,
        examId: examId
      })
      .then(response => {
        commit('receiveHydragram', response.data.scale)
      })
  },
  getPercentiles({ commit, rootState }, examId) {
    return this.$axios
      .post('/bodygram-backend/v1/app/functions/scores-and-indexes', {
        accessToken: rootState.user.token,
        examId: examId
      })
      .then(response => {
        const ffmi = response.data.percentiles.ffmi
        ffmi.categories.push(100)
        ffmi.series.forEach(item => {
          item.data.push([100, item.data[item.data.length - 1][1]])
        })

        const fmi = response.data.percentiles.fmi
        fmi.categories.push(100)
        fmi.series.forEach(item => {
          item.data.push([100, item.data[item.data.length - 1][1]])
        })

        commit('receiveFfmi', ffmi)
        commit('receiveFmi', response.data.percentiles.fmi)
        commit('receivePercentileExams', response.data.exams)
        commit('receivePercentileEstimates', response.data.estimates)
        commit('receiveHeightPercentiles', response.data.percentiles.height)
        commit('receiveBmiPercentiles', response.data.percentiles.bmi)
        commit('receiveWeightPercentiles', response.data.percentiles.weight)
      })
  }
}
export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}

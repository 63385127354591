const stepsImageObj = {
  'Step-01-Connecting-device.jpg':
    'images/virtual-assistance-images/steps-images/Step-01-Connecting-device.jpg',
  'Step-02-Material-needed-01.jpg':
    'images/virtual-assistance-images/steps-images/Step-02-Material-needed-01.jpg',
  'Step-02-Material-needed-02.jpg':
    'images/virtual-assistance-images/steps-images/Step-02-Material-needed-02.jpg',
  'Step-04-Test-calibration.jpg':
    'images/virtual-assistance-images/steps-images/Step-04-Test-calibration.jpg',
  'Step-05-Verifing-Value.jpg':
    'images/virtual-assistance-images/steps-images/Step-05-Verifing-Value.jpg',
  'Step-06-Insert-Cable-1.jpg':
    'images/virtual-assistance-images/steps-images/Step-06-Insert-Cable-1.jpg',
  'Step-06-Insert-Cable-2.jpg':
    'images/virtual-assistance-images/steps-images/Step-06-Insert-Cable-2.jpg',
  'Step-06-Insert-Cable-3.jpg':
    'images/virtual-assistance-images/steps-images/Step-06-Insert-Cable-3.jpg',
  'Step-06-Insert-Cable-4.jpg':
    'images/virtual-assistance-images/steps-images/Step-06-Insert-Cable-4.jpg',
  'Step-06-Insert-Cable-5.jpg':
    'images/virtual-assistance-images/steps-images/Step-06-Insert-Cable-5.jpg',
  'Step-06-Insert-Cable-6.jpg':
    'images/virtual-assistance-images/steps-images/Step-06-Insert-Cable-6.jpg',
  'Step-06-Insert-Cable-modifica-marzo-2022-1.jpg':
    'images/virtual-assistance-images/steps-images/Step-06-Insert-Cable-modifica-marzo-2022-1.jpg',
  'Step-06-Insert-Cable-modifica-marzo-2022-2.jpg':
    'images/virtual-assistance-images/steps-images/Step-06-Insert-Cable-modifica-marzo-2022-2.jpg',
  'Step-06-Insert-Cable-modifica-marzo-2022-3.jpg':
    'images/virtual-assistance-images/steps-images/Step-06-Insert-Cable-modifica-marzo-2022-3.jpg',
  'Step-06-Insert-Cable-modifica-marzo-2022-4.jpg':
    'images/virtual-assistance-images/steps-images/Step-06-Insert-Cable-modifica-marzo-2022-4.jpg',
  'Step-06-Insert-Cable-modifica-marzo-2022-5.jpg':
    'images/virtual-assistance-images/steps-images/Step-06-Insert-Cable-modifica-marzo-2022-5.jpg',
  'Step-06-Insert-Cable-modifica-marzo-2022-6.jpg':
    'images/virtual-assistance-images/steps-images/Step-06-Insert-Cable-modifica-marzo-2022-6.jpg',
  'Step-07-Tester-measuring-01.jpg':
    'images/virtual-assistance-images/steps-images/Step-07-Tester-measuring-01.jpg',
  'Step-07-Tester-measuring-modifica-marzo-2022-01.jpg':
    'images/virtual-assistance-images/steps-images/Step-07-Tester-measuring-modifica-marzo-2022-01.jpg',
  'Step-09-Verifing-cable-with-tester-1.jpg':
    'images/virtual-assistance-images/steps-images/Step-09-Verifing-cable-with-tester-1.jpg',
  'Step-09-Verifing-cable-with-tester-2.jpg':
    'images/virtual-assistance-images/steps-images/Step-09-Verifing-cable-with-tester-2.jpg',
  'Step-09-Verifing-cable-with-tester-3.jpg':
    'images/virtual-assistance-images/steps-images/Step-09-Verifing-cable-with-tester-3.jpg',
  'Step-09-Verifing-cable-with-tester-4.jpg':
    'images/virtual-assistance-images/steps-images/Step-09-Verifing-cable-with-tester-4.jpg',
  'Step-09-Verifing-cable-with-tester-5.jpg':
    'images/virtual-assistance-images/steps-images/Step-09-Verifing-cable-with-tester-5.jpg',
  'Step-09-Verifing-cable-with-tester-6.jpg':
    'images/virtual-assistance-images/steps-images/Step-09-Verifing-cable-with-tester-6.jpg',
  'Step-09-Verifing-cable-with-tester-7.jpg':
    'images/virtual-assistance-images/steps-images/Step-09-Verifing-cable-with-tester-7.jpg',
  'Step-09-Verifing-cable-with-tester-8.jpg':
    'images/virtual-assistance-images/steps-images/Step-09-Verifing-cable-with-tester-8.jpg',
  'Step-09-Verifing-cable-with-tester-modifica-marzo2022-1.jpg':
    'images/virtual-assistance-images/steps-images/Step-09-Verifing-cable-with-tester-modifica-marzo2022-1.jpg',
  'Step-09-Verifing-cable-with-tester-modifica-marzo2022-2.jpg':
    'images/virtual-assistance-images/steps-images/Step-09-Verifing-cable-with-tester-modifica-marzo2022-2.jpg',
  'Step-09-Verifing-cable-with-tester-modifica-marzo2022-3.jpg':
    'images/virtual-assistance-images/steps-images/Step-09-Verifing-cable-with-tester-modifica-marzo2022-3.jpg',
  'Step-09-Verifing-cable-with-tester-modifica-marzo2022-4.jpg':
    'images/virtual-assistance-images/steps-images/Step-09-Verifing-cable-with-tester-modifica-marzo2022-4.jpg',
  'Step-09-Verifing-cable-with-tester-modifica-marzo2022-5.jpg':
    'images/virtual-assistance-images/steps-images/Step-09-Verifing-cable-with-tester-modifica-marzo2022-5.jpg',
  'Step-09-Verifing-cable-with-tester-modifica-marzo2022-6.jpg':
    'images/virtual-assistance-images/steps-images/Step-09-Verifing-cable-with-tester-modifica-marzo2022-6.jpg',
  'Step-09-Verifing-cable-with-tester-modifica-marzo2022-7.jpg':
    'images/virtual-assistance-images/steps-images/Step-09-Verifing-cable-with-tester-modifica-marzo2022-7.jpg',
  'Step-09-Verifing-cable-with-tester-modifica-marzo2022-8.jpg':
    'images/virtual-assistance-images/steps-images/Step-09-Verifing-cable-with-tester-modifica-marzo2022-8.jpg',
  'Step-09-Verifing-cable-with-tester-modifica-Aprile-2023_1.jpg':
    'images/virtual-assistance-images/steps-images/Step-09-Verifing-cable-with-tester-modifica-Aprile-2023_1.jpg',
  'Step-09-Verifing-cable-with-tester-modifica-Aprile-2023_2.jpg':
    'images/virtual-assistance-images/steps-images/Step-09-Verifing-cable-with-tester-modifica-Aprile-2023_2.jpg',
  'Step-09-Verifing-cable-with-tester-modifica-Aprile-2023_3.jpg':
    'images/virtual-assistance-images/steps-images/Step-09-Verifing-cable-with-tester-modifica-Aprile-2023_3.jpg',
  'Step-09-Verifing-cable-with-tester-modifica-Aprile-2023_B_1.jpg':
    'images/virtual-assistance-images/steps-images/Step-09-Verifing-cable-with-tester-modifica-Aprile-2023_B_1.jpg',
  'Step-09-Verifing-cable-with-tester-modifica-Aprile-2023_B_2.jpg':
    'images/virtual-assistance-images/steps-images/Step-09-Verifing-cable-with-tester-modifica-Aprile-2023_B_2.jpg',
  'Step-09-Verifing-cable-with-tester-modifica-Aprile-2023_B_3.jpg':
    'images/virtual-assistance-images/steps-images/Step-09-Verifing-cable-with-tester-modifica-Aprile-2023_B_3.jpg',
  'Step-09-Verifing-cable-with-tester-modifica-Aprile-2023_B_5.jpg':
    'images/virtual-assistance-images/steps-images/Step-09-Verifing-cable-with-tester-modifica-Aprile-2023_B_5.jpg',
  'Step-09-Verifing-cable-with-tester-modifica-Aprile-2023_B_6.jpg':
    'images/virtual-assistance-images/steps-images/Step-09-Verifing-cable-with-tester-modifica-Aprile-2023_B_6.jpg',
  'Step-09-Verifing-cable-with-tester-modifica-Aprile-2023_B_7.jpg':
    'images/virtual-assistance-images/steps-images/Step-09-Verifing-cable-with-tester-modifica-Aprile-2023_B_7.jpg',
  'Step-09-Verifing-cable-with-tester-modifica-Aprile-2023_B_8.jpg':
    'images/virtual-assistance-images/steps-images/Step-09-Verifing-cable-with-tester-modifica-Aprile-2023_B_8.jpg'
}

const statusIcons = {
  success: 'images/virtual-assistance-images/ok-virtualAssistant.jpg',
  warning: 'images/virtual-assistance-images/warning-virtualAssistant.jpg'
}

const compatibilityTable = new Map()
compatibilityTable.set('12@17', {
  actualStep: 0,
  totalSteps: 8
})

const minVersions = {
  HW: 1.2,
  FW: 2.0
}

export { statusIcons, compatibilityTable, stepsImageObj, minVersions }

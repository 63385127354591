const state = () => ({
  exam: null,
  references: null
})

const getters = {
  exam: state => state.exam,
  references: state => state.references
}

const mutations = {
  receiveExam(state, exam) {
    state.exam = exam
  },
  receiveReferences(state, references) {
    state.references = references
  },
  clearExam(state) {
    state.exam = null
    state.references = null
  }
}

const actions = {
  getExam({ commit, rootState }, examId) {
    return this.$axios
      .post('/bodygram-backend/v1/app/measurements/get', {
        accessToken: rootState.user.token,
        examId: examId
      })
      .then(response => {
        commit('receiveExam', response.data.measurement)
        commit('receiveReferences', response.data.references)
      })
      .catch(() => {
        commit('clearExam')
      })
  },
  setExam({ dispatch, rootState }, exam) {
    return this.$axios
      .post('/bodygram-backend/v1/app/measurements/set', {
        accessToken: rootState.user.token,
        measurement: exam
      })
      .then(() => {
        dispatch('getExam', exam.examId)
      })
  },
  deleteExam({ rootState }, examId) {
    return this.$axios.post('/bodygram-backend/v1/app/measurements/delete', {
      accessToken: rootState.user.token,
      examId: examId
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}

<template>
  <BModal
    ref="change-password-modal"
    :title="$t('app.settings.changePassword')"
    :ok-title="$t('app.settings.change')"
    :cancel-title="$t('app.forms.close')"
    centered
    no-close-on-backdrop
    @ok.prevent="onSubmit"
  >
    <BAlert :show="!!error" variant="danger">{{ error }}</BAlert>

    <BForm @submit.prevent="onSubmit">
      <BRow>
        <BCol cols="12">
          <BFormGroup :label="$t('app.users.labels.currentPassword')">
            <BFormInput
              v-model="currentPassword"
              v-validate="'required'"
              :state="errors.has('currentPassword') ? false : null"
              :placeholder="$t('app.users.placeholders.currentPassword')"
              type="password"
              name="currentPassword"
              autofocus
            />
          </BFormGroup>
        </BCol>

        <BCol cols="12">
          <BFormGroup :label="$t('app.users.labels.newPassword')">
            <BFormInput
              v-model="password"
              v-validate="'required'"
              :state="errors.has('password') ? false : null"
              :placeholder="$t('app.users.placeholders.password')"
              type="password"
              ref="password"
              name="password"
            />
          </BFormGroup>
        </BCol>

        <BCol cols="12">
          <BFormGroup :label="$t('app.users.labels.newRePassword')">
            <BFormInput
              v-model="rePassword"
              v-validate="'required|confirmed:password'"
              :state="errors.has('rePassword') ? false : null"
              :placeholder="$t('app.users.placeholders.rePassword')"
              type="password"
              name="rePassword"
              data-vv-as="password"
            />
          </BFormGroup>
        </BCol>
      </BRow>
    </BForm>
  </BModal>
</template>

<script>
export default {
  props: {
    superLog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      error: null,
      currentPassword: null,
      password: null,
      rePassword: null
    }
  },
  methods: {
    showModal() {
      this.error = null
      this.currentPassword = null
      this.password = null
      this.rePassword = null

      this.$refs['change-password-modal'].show()
    },
    onSubmit() {
      this.error = null

      this.$validator.validate().then(async valid => {
        if (valid) {
          try {
            this.$nuxt.$loading.start()

            await this.$axios.post('/users/change-password', {
              accessToken: this.$store.getters['user/token'],
              currentPassword: this.currentPassword,
              newPassword: this.password,
              superLog: this.superLog
            })

            this.$bvToast.toast(
              this.$t('app.settings.passwordSuccessfullyChanged'),
              {
                title: this.$t('app.settings.changePassword'),
                variant: 'success'
              }
            )

            this.$refs['change-password-modal'].hide()
          } catch (error) {
            this.error = this.$getErrorMessage(error)
          } finally {
            this.$nuxt.$loading.finish()
          }
        }
      })
    }
  }
}
</script>

const state = () => ({
  report: null,
  list: [
    {
      slug: 'baseline',
      name: 'baseline',
      json: [
        {
          items: [
            {
              component: 'ReportHeader'
            }
          ]
        },
        {
          items: [
            {
              component: 'Profile'
            },
            {
              component: 'Photos'
            }
          ]
        },
        {
          items: [
            {
              component: 'Functional'
            }
          ]
        },
        {
          items: [
            {
              component: 'Anthropometric'
            }
          ]
        },
        {
          items: [
            {
              component: 'Bia'
            }
          ]
        },
        {
          items: [
            {
              component: 'ReportFooter'
            }
          ]
        }
      ]
    },
    {
      slug: 'standard',
      name: 'standard',
      json: [
        {
          items: [
            {
              component: 'ReportHeader'
            }
          ]
        },
        {
          items: [
            {
              component: 'Profile'
            },
            {
              component: 'Photos'
            }
          ]
        },
        {
          items: [
            {
              component: 'Functional'
            }
          ]
        },
        {
          items: [
            {
              component: 'Anthropometric'
            }
          ]
        },
        {
          items: [
            {
              component: 'Bia'
            }
          ]
        },
        {
          items: [
            {
              component: 'ReportFooter'
            }
          ]
        }
      ]
    },
    {
      slug: 'followUp',
      name: 'followUp',
      json: [
        {
          items: [
            {
              component: 'ReportHeader'
            }
          ]
        },
        {
          items: [
            {
              component: 'FollowUp'
            }
          ]
        },
        {
          items: [
            {
              component: 'ReportFooter'
            }
          ]
        }
      ]
    },
    {
      slug: 'zScore',
      name: 'zScore',
      json: [
        {
          items: [
            {
              component: 'ReportHeader'
            }
          ]
        },
        {
          items: [
            {
              component: 'ZScore'
            }
          ]
        },
        {
          items: [
            {
              component: 'ReportFooter'
            }
          ]
        }
      ]
    }
  ]
})

const getters = {
  report: state => state.report,
  list: state => state.list
}

const mutations = {
  receiveReport(state, report) {
    state.report = report
  }
}

const actions = {
  getReport({ state, commit }, reportSlug) {
    const report = state.list.find(report => report.slug === reportSlug)
    commit('receiveReport', report)
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}

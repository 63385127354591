const state = () => ({
  report: null,
  list: [
    {
      slug: 'baseline-left-right',
      title: 'app.common.baselineLeftRight',
      json: [
        {
          items: [
            {
              component: 'ReportHeader'
            }
          ]
        },
        {
          items: [
            {
              component: 'BaselineLeftRight'
            }
          ]
        },
        {
          items: [
            {
              component: 'ReportNote'
            }
          ]
        },
        {
          items: [
            {
              component: 'ReportFooter'
            }
          ]
        }
      ]
    },
    {
      slug: 'baseline-upper-lower',
      title: 'app.common.baselineUpperLower',
      json: [
        {
          items: [
            {
              component: 'ReportHeader'
            }
          ]
        },
        {
          items: [
            {
              component: 'BaselineUpperLower'
            }
          ]
        },
        {
          items: [
            {
              component: 'ReportNote'
            }
          ]
        },
        {
          items: [
            {
              component: 'ReportFooter'
            }
          ]
        }
      ]
    },
    {
      slug: 'follow-up-left-right',
      title: 'app.common.followUpLeftRight',
      json: [
        {
          items: [
            {
              component: 'ReportHeader'
            }
          ]
        },
        {
          items: [
            {
              component: 'FollowUpLeftRight'
            }
          ]
        },
        {
          items: [
            {
              component: 'ReportNote'
            }
          ]
        },
        {
          items: [
            {
              component: 'ReportFooter'
            }
          ]
        }
      ]
    },
    {
      slug: 'follow-up-upper-lower',
      title: 'app.common.followUpUpperLowerReport',
      json: [
        {
          items: [
            {
              component: 'ReportHeader'
            }
          ]
        },
        {
          items: [
            {
              component: 'FollowUpUpperLower'
            }
          ]
        },
        {
          items: [
            {
              component: 'ReportNote'
            }
          ]
        },
        {
          items: [
            {
              component: 'ReportFooter'
            }
          ]
        }
      ]
    },
    {
      slug: 'follow-up-injury',
      title: 'app.common.followUpInjuryReport',
      json: [
        {
          items: [
            {
              component: 'ReportHeader'
            }
          ]
        },
        {
          items: [
            {
              component: 'FollowUpInjury'
            }
          ]
        },
        {
          items: [
            {
              component: 'ReportNote'
            }
          ]
        },
        {
          items: [
            {
              component: 'ReportFooter'
            }
          ]
        }
      ]
    },
    {
      slug: 'baseline-injury',
      title: 'app.common.baselineInjuryReport',
      json: [
        {
          items: [
            {
              component: 'ReportHeader'
            }
          ]
        },
        {
          items: [
            {
              component: 'BaselineInjury'
            }
          ]
        },
        {
          items: [
            {
              component: 'ReportNote'
            }
          ]
        },
        {
          items: [
            {
              component: 'ReportFooter'
            }
          ]
        }
      ]
    }
  ]
})

const getters = {
  report: state => state.report,
  list: state => state.list
}

const mutations = {
  receiveReport(state, report) {
    state.report = report
  }
}

const actions = {
  getReport({ state, commit }, reportSlug) {
    const report = state.list.find(report => report.slug === reportSlug)
    commit('receiveReport', report)
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}

const state = () => ({
  list: null,
  appointment: null,
  next: null
})
const getters = {
  list: state => state.list,
  appointment: state => state.appointment,
  next: state => state.next,
  events: state => {
    if (state.list) {
      return state.list.map(item => {
        item.title = item.patient.lastName + ' ' + item.patient.firstName

        item.start = item.appointedAt
        return item
      })
    }
  }
}
const mutations = {
  receiveList(state, list) {
    state.list = list
  },
  receiveNext(state, appointment) {
    state.next = appointment
  },
  select(state, appointment) {
    state.appointment = appointment
  }
}
const actions = {
  getList({ commit, rootState }) {
    return this.$axios
      .post('/bodygram-backend/v1/core/appointments/schedule', {
        accessToken: rootState.user.token,
        // since: '2019-01-01 00:00',
        until: '+1 year'
      })
      .then(response => {
        commit('receiveList', response.data.appointments)
      })
      .catch(error => console.error(error))
  },
  getNext({ commit, rootState }) {
    return this.$axios
      .post('/bodygram-backend/v1/core/appointments/next', {
        accessToken: rootState.user.token
      })
      .then(response => {
        commit('receiveNext', response.data.appointment)
      })
      .catch(error => console.error(error))
  },
  createAppointment({ dispatch, rootState }, appointment) {
    return this.$axios
      .post('/bodygram-backend/v1/core/appointments/create', {
        accessToken: rootState.user.token,
        appointment: appointment
      })
      .then(() => {
        dispatch('getList')
      })
      .catch(error => console.error(error))
  },
  updateAppointment({ dispatch, rootState }, appointment) {
    return this.$axios
      .post('/bodygram-backend/v1/core/appointments/update', {
        accessToken: rootState.user.token,
        appointment: appointment
      })
      .then(() => {
        dispatch('getList')
      })
      .catch(error => console.error(error))
  },
  deleteAppointment({ dispatch, rootState }, id) {
    return this.$axios
      .post('/bodygram-backend/v1/core/appointments/delete', {
        accessToken: rootState.user.token,
        id
      })
      .then(() => {
        dispatch('getList')
      })
      .catch(error => {
        throw error
      })
  }
}
export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}

export default function({ app, store, redirect }) {
  const regionalApplication = store.getters['applications/regional']

  if (
    regionalApplication &&
    regionalApplication.signature &&
    ((typeof regionalApplication.signature.daysToLock === 'number' &&
      regionalApplication.signature.daysToLock <= 0) ||
      regionalApplication.signature.forceSigningContract)
  ) {
    redirect(app.localePath('/accept?app=REG'))
  }
}

import Vue from 'vue'
import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'
import annotationsInit from 'highcharts/modules/annotations'

annotationsInit(Highcharts)

Highcharts.theme = {
  colors: [
    '#27bad9',
    '#62ae52',
    '#d8413e',
    '#ef7b0b',
    '#3774ab',
    '#90cc84',
    '#f0ac4a',
    '#FFF263',
    '#6AF9C4'
  ],
  chart: {
    backgroundColor: 'transparent'
  },
  credits: {
    enabled: false
  }
}
Highcharts.setOptions(Highcharts.theme)

Vue.use(HighchartsVue)

<template>
  <b-modal
    ref="modal-to-request-info-about-fitness-app"
    header-bg-variant="light-blue"
    body-class="px-5"
    header-class="px-5"
    centered
    hide-footer
  >
    <template #modal-header>
      <div class="w-100 d-flex align-items-center justify-content-between">
        <FitnessLogo style="height: 50px; width: auto" />

        <b-icon-x
          variant="white"
          font-scale="2.5"
          style="cursor: pointer"
          @click="hide"
        />
      </div>
    </template>

    <b-alert :show="!!successMessage" variant="success">
      {{ successMessage }}
    </b-alert>

    <b-alert :show="!!errorMessage" variant="danger">
      {{ errorMessage }}
    </b-alert>

    <h5 class="text-light-blue mt-4 mb-3">
      {{ $t('app.modalToRequestInfoAboutFitnessApp.title') }}
    </h5>

    <div>
      {{ $t('app.modalToRequestInfoAboutFitnessApp.description') }}
    </div>

    <div class="text-center my-4">
      <b-button variant="light-blue" @click="requestInfoAboutFitnessApp">
        {{ $t('app.modalToRequestInfoAboutFitnessApp.buttonTitle') }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import FitnessLogo from '~/components/fitness/logo.vue'

export default {
  components: {
    FitnessLogo
  },
  data() {
    return {
      errorMessage: null,
      successMessage: null
    }
  },
  methods: {
    open() {
      this.errorMessage = null
      this.successMessage = null

      this.$refs['modal-to-request-info-about-fitness-app'].show()
    },
    hide() {
      this.$refs['modal-to-request-info-about-fitness-app'].hide()
    },
    async requestInfoAboutFitnessApp() {
      try {
        this.errorMessage = null
        this.successMessage = null

        const { data } = await this.$axios.post(
          '/bodygram-backend/v1/settings/licenses/request',
          {
            accessToken: this.$store.getters['user/token'],
            applicationCode: 'FIT'
          }
        )

        if (data.success) {
          this.successMessage = this.$t(
            'app.modalToRequestInfoAboutFitnessApp.successMessage'
          )
        }
      } catch (error) {
        this.errorMessage = this.$getErrorMessage(error)
      }
    }
  }
}
</script>

<template>
  <div class="container-fluid mt-3">
    <b-breadcrumb
      :class="$route.name.includes('reports-') ? 'breadcrumb-resized' : ''"
      :items="items"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      exam: 'keto/exam',
      // bia: 'bia/exam',
      circumference: 'circumference/exam',
      // skinfold: 'skinfold/exam',
      // training: 'training/exam',
      // cardio: 'cardio/exam',
      keto: 'keto/exam',
      group: 'group/group',
      patient: 'patient/patient',
      crumb: 'crumb/crumb'
    }),
    items() {
      const crumbs = [
        {
          html: '<i class="fas fa-home"></i>',
          to: this.localePath('keto')
        }
      ]
      if (this.$route.name.includes('schedule')) {
        crumbs.push({
          text: this.$t('app.schedule.schedule'),
          to: this.localePath('keto-schedule')
        })
      } else if (this.$route.name.includes('search')) {
        crumbs.push({
          text: this.$t('app.common.search'),
          to: this.localePath('keto-search')
        })
      } else {
        crumbs.push({
          text: this.$t('app.folders.folders'),
          to: this.localePath('keto-groups')
        })
      }
      if (this.$route.params.groupId) {
        crumbs.push({
          text: this.group ? this.group.name : '',
          to: this.localePath(`/keto/groups/${this.$route.params.groupId}`)
        })
      }
      if (this.$route.params.patientId) {
        crumbs.push({
          text: this.patient
            ? this.patient.firstName + ' ' + this.patient.lastName
            : '',
          to: this.localePath(
            `/keto/groups/${this.$route.params.groupId}/patients/${this.$route.params.patientId}`
          )
        })
      }
      // if (this.$route.params.skinfoldId) {
      //   crumbs.push({
      //     text: this.skinfold
      //       ? this.$t('app.common.skinfoldOf') +
      //         ' ' +
      //         this.getDateTime(this.skinfold.createdAt)
      //       : '',
      //     to: this.localePath(
      //       `/keto/groups/${this.$route.params.groupId}/patients/${this.$route.params.patientId}/skinfolds/${this.$route.params.skinfoldId}`
      //     )
      //   })
      // }
      if (this.$route.params.circumferenceId) {
        crumbs.push({
          text: this.circumference
            ? this.$t('app.common.circumference') +
              ' ' +
              this.getDateTime(this.circumference.createdAt)
            : '',
          to: this.localePath(
            `/keto/groups/${this.$route.params.groupId}/patients/${this.$route.params.patientId}/circumference/${this.$route.params.circumferenceId}`
          )
        })
      }
      // if (this.$route.params.trainingId) {
      //   crumbs.push({
      //     text: this.training
      //       ? this.$t('app.common.trainingOf') +
      //         ' ' +
      //         this.getDateTime(this.training.createdAt)
      //       : '',
      //     to: this.localePath(
      //       `/keto/groups/${this.$route.params.groupId}/patients/${this.$route.params.patientId}/trainings/${this.$route.params.trainingId}`
      //     )
      //   })
      // }
      // if (this.$route.params.biaId) {
      //   crumbs.push({
      //     text: this.bia
      //       ? 'BIA of ' + this.getDateTime(this.bia.createdAt)
      //       : '',
      //     to: this.localePath(
      //       `/keto/groups/${this.$route.params.groupId}/patients/${this.$route.params.patientId}/bia/${this.$route.params.biaId}`
      //     )
      //   })
      // }
      // if (this.$route.params.cardioId) {
      //   crumbs.push({
      //     text: this.cardio
      //       ? this.$t('app.common.cardioOf') +
      //         ' ' +
      //         this.getDateTime(this.cardio.createdAt)
      //       : '',
      //     to: this.localePath(
      //       `/keto/groups/${this.$route.params.groupId}/patients/${this.$route.params.patientId}/cardio/${this.$route.params.cardioId}`
      //     )
      //   })
      // }
      if (this.$route.params.ketoId) {
        crumbs.push({
          text: this.keto
            ? this.$t('app.common.ketoOf') +
              ' ' +
              this.getDateTime(this.keto.createdAt)
            : '',
          to: this.localePath(
            `/keto/groups/${this.$route.params.groupId}/patients/${this.$route.params.patientId}/keto/${this.$route.params.ketoId}`
          )
        })
      }
      if (this.$route.params.examId) {
        crumbs.push({
          text: this.exam
            ? `${this.$t('app.common.testOf')} ${this.getDateTime(
                this.exam.effectiveAt
              )}`
            : '',
          to: this.localePath(
            `/keto/groups/${this.$route.params.groupId}/patients/${this.$route.params.patientId}/exams/${this.$route.params.examId}`
          )
        })
      }
      if (this.$route.name.includes('reports')) {
        if (this.$route.name.includes('bia')) {
          crumbs.push({
            text: this.$t('app.reports.reports'),
            to: this.localePath({
              name: 'keto-groups-groupId-patients-patientId-bia-biaId-reports',
              params: {
                groupId: this.$route.params.groupId,
                patientId: this.$route.params.patientId,
                biaId: this.$route.params.biaId
              }
            })
          })
        } else {
          crumbs.push({
            text: this.$t('app.reports.reports'),
            to: this.localePath({
              name:
                'keto-groups-groupId-patients-patientId-exams-examId-reports',
              params: {
                groupId: this.$route.params.groupId,
                patientId: this.$route.params.patientId,
                examId: this.$route.params.examId
              }
            })
          })
        }
      }
      if (this.crumb) {
        crumbs.push({
          text: this.crumb
        })
      }
      return crumbs
    }
  },
  created() {
    if (this.$route.params.examId && !this.exam) {
      this.getExam(this.$route.params.examId)
    }
    // if (this.$route.params.biaId && !this.bia) {
    //   this.getBia(this.$route.params.biaId)
    // }
    // if (this.$route.params.cardioId && !this.cardio) {
    //   this.getCardio(this.$route.params.cardioId)
    // }
    if (this.$route.params.ketoId && !this.keto) {
      this.getKeto(this.$route.params.ketoId)
    }
    // if (this.$route.params.skinfoldId && !this.skinfold) {
    //   this.getSkinfold(this.$route.params.skinfoldId)
    // }
    // if (this.$route.params.trainingId && !this.training) {
    //   this.getTraining(this.$route.params.trainingId)
    // }
    if (this.$route.params.circumferenceId && !this.circumference) {
      this.getCircumference(this.$route.params.circumferenceId)
    }
    if (this.$route.params.groupId && !this.group) {
      this.getGroup(this.$route.params.groupId)
    }
    if (this.$route.params.patientId && !this.patient) {
      this.getPatient(this.$route.params.patientId)
    }
  },
  methods: {
    ...mapActions({
      getExam: 'keto/getExam',
      // getBia: 'bia/getExam',
      // getSkinfold: 'skinfold/getExam',
      // getTraining: 'training/getExam',
      getCircumference: 'keto/getCircumference',
      // getCardio: 'cardio/getExam',
      getKeto: 'keto/getExam',
      getGroup: 'group/getGroup',
      getPatient: 'patient/getPatient'
    }),
    getDateTime(date) {
      return this.$dayjs(date).format('L LT')
    }
  }
}
</script>

export default async function({ app, store, route, redirect }) {
  if (!store.getters['user/isAuthenticated']) {
    store.commit('user/logout')

    redirect(
      app.localeRoute({
        path: '/login'
      })
    )
  } else {
    store.commit('user/setGoogleAnalyticsUserId')
    if (!store.getters['user/settings']) {
      await store.dispatch('user/getSettings')
      if (
        !store.getters['applications/list'] &&
        !route.name.startsWith('settings-licenses')
      ) {
        await store.dispatch('applications/getApplications')
      }
    }
  }
}

const state = () => ({
  weight: null,
  activity: null,
  activities: null,
  kinds: null
})

const getters = {
  weight: state => state.weight,
  activity: state => state.activity,
  activities: state => state.activities,
  kinds: state => state.kinds,
  currentActivity: state => {
    return (
      state.kinds &&
      state.activity &&
      state.kinds.map(kind => {
        const patientActivity = state.activity.activityLogs.filter(
          log => log.activityKindId === kind.id
        )
        if (patientActivity.length > 0) {
          return Object.assign({}, kind, {
            hoursPerWeek: patientActivity[0].hoursPerWeek
          })
        }
        return Object.assign({}, kind, {
          hoursPerWeek: null
        })
      })
    )
  },
  currentActivityTotal: state => {
    const result = {
      calories: 0,
      met: 0
    }
    state.activity &&
      state.activity.activityLogs.forEach(activity => {
        result.calories +=
          activity.hoursPerWeek *
          activity.activityKind.conversionFactor *
          state.weight
        result.met +=
          activity.hoursPerWeek * activity.activityKind.conversionFactor
      })
    return result
  }
}

const mutations = {
  receiveWeight(state, weight) {
    state.weight = weight
  },
  receiveActivity(state, activity) {
    state.activity = activity
  },
  receiveActivities(state, activities) {
    state.activities = activities
  },
  receiveKinds(state, kinds) {
    state.kinds = kinds
  },
  setHours(state, { value, activitySource }) {
    const activityIndex = state.activity.activityLogs.findIndex(
      activity => activity.activityKindId === activitySource.id
    )
    if (activityIndex !== -1) {
      state.activity.activityLogs[activityIndex].hoursPerWeek = +value
    } else {
      state.activity.activityLogs.push({
        ...activitySource,
        activityKind: {
          conversionFactor: activitySource.conversionFactor
        },
        activityKindId: activitySource.id,
        hoursPerWeek: +value
      })
    }
  }
}

const actions = {
  getWeight({ commit, rootState }) {
    return this.$axios
      .post('/bodygram-backend/v1/app/exams/list', {
        accessToken: rootState.user.token,
        patientId: rootState.patient.patient.id
      })
      .then(response => {
        commit('receiveWeight', response.data.exams[0].weight)
      })
  },
  getActivity({ commit, rootState }, id) {
    return this.$axios
      .post('/bodygram-backend/v1/app/activities/get', {
        accessToken: rootState.user.token,
        id
      })
      .then(response => {
        commit('receiveActivity', response.data.activity)
      })
  },
  getActivities({ commit, rootState }, patientId) {
    return this.$axios
      .post('/bodygram-backend/v1/app/activities/list', {
        accessToken: rootState.user.token,
        patientId
      })
      .then(response => {
        commit('receiveActivities', response.data.activities)
      })
  },
  getKinds({ commit, rootState }) {
    return this.$axios
      .post('/bodygram-backend/v1/app/activities/kinds', {
        accessToken: rootState.user.token
      })
      .then(response => {
        commit('receiveKinds', response.data.kinds)
      })
  },
  addActivity({ state, rootState, dispatch }, patientId) {
    const activities = state.activity.activityLogs.filter(
      activity => activity.hoursPerWeek && activity.hoursPerWeek > 0
    )
    if (activities.length > 0) {
      return this.$axios
        .post('/bodygram-backend/v1/app/activities/create', {
          accessToken: rootState.user.token,
          activity: {
            patientId,
            activityLogs: activities.map(activity => {
              return {
                activityKindId: activity.activityKindId,
                hoursPerWeek: activity.hoursPerWeek
              }
            })
          }
        })
        .then(() => {
          dispatch('getActivities', patientId)
        })
    }
  },
  updateActivity({ state, rootState, dispatch }, patientId) {
    return this.$axios
      .post('/bodygram-backend/v1/app/activities/update', {
        accessToken: rootState.user.token,
        activity: {
          id: state.activity.activityLogs[0].activityId,
          activityLogs: state.activity.activityLogs
            .filter(
              activity => activity.hoursPerWeek && activity.hoursPerWeek > 0
            )
            .map(activity => {
              return {
                activityKindId: activity.activityKindId,
                hoursPerWeek: activity.hoursPerWeek
              }
            })
        }
      })
      .then(() => {
        dispatch('getActivities', patientId)
      })
  },
  deleteActivity({ rootState, dispatch }, activityId) {
    return this.$axios
      .post('/bodygram-backend/v1/app/activities/delete', {
        accessToken: rootState.user.token,
        id: activityId
      })
      .then(() => {
        dispatch('getActivities', rootState.patient.patient.id)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}

<template>
  <BModal
    ref="move-data-modal"
    :title="$t('app.config.moveData')"
    :hide-header-close="isLoading"
    centered
    no-close-on-backdrop
    no-close-on-esc
    @hide="onModalHide"
  >
    <b-form-group
      v-if="!isLoading && !isSuccess"
      :label="$t('app.config.labels.pathToDB')"
    >
      <b-input-group>
        <b-form-input
          v-model="pathToDB"
          v-validate="'required'"
          :state="errors.has('pathToDB') ? false : null"
          :placeholder="$t('app.config.placeholders.pathToDB')"
          name="pathToDB"
        />

        <b-input-group-append>
          <b-button type="button" variant="primary" @click="selectDirectory">
            {{ $t('app.config.selectDirectory') }}
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <div v-if="isLoading" class="my-3 w-100 text-center">
      <BSpinner variant="primary" />

      <div class="mb-3" style="white-space: pre-line;">
        {{ $t('app.moveData.loadingMessage') }}
      </div>
    </div>

    <div v-if="isSuccess" class="my-3 w-100 text-center text-success">
      {{ $t('app.moveData.success') }}
    </div>

    <template #modal-footer="{ cancel }">
      <BButton :disabled="isLoading" variant="secondary" @click="cancel">
        {{ $t('app.forms.close') }}
      </BButton>

      <BButton
        v-if="!isSuccess"
        :disabled="isLoading"
        variant="primary"
        @click="moveData"
      >
        {{ $t('app.import.import') }}
      </BButton>
    </template>
  </BModal>
</template>

<script>
export default {
  data() {
    return {
      pathToDB: null,
      isSuccess: false,
      isLoading: false
    }
  },
  methods: {
    showModal() {
      this.pathToDB = null
      this.isSuccess = false
      this.isLoading = false

      this.$refs['move-data-modal'].show()
    },
    onModalHide() {
      if (this.isSuccess) {
        this.$emit('done')
      }
    },
    async selectDirectory() {
      try {
        this.pathToDB = await this.$ipc('selectDirectory')
      } catch (error) {
        console.error(error)
      }
    },
    moveData() {
      this.$validator.validate().then(async valid => {
        if (valid) {
          try {
            this.$nuxt.$loading.start()
            this.isLoading = true

            this.isSuccess = false

            await this.$ipc('moveData', {
              path: this.pathToDB
            })

            this.isSuccess = true
          } catch (error) {
            this.error = this.$getErrorMessage(error)
          } finally {
            this.$nuxt.$loading.finish()
            this.isLoading = false
          }
        }
      })
    }
  }
}
</script>

<template>
  <BModal
    ref="modal-for-selecting-sync-method"
    :title="$t('app.offline.selectSyncMethod.title')"
    centered
  >
    <div class="pre-line">{{ $t('app.offline.selectSyncMethod.text') }}</div>

    <template #modal-footer="{ cancel }">
      <BButton variant="secondary" @click="cancel">
        {{ $t('app.forms.close') }}
      </BButton>

      <BButton variant="warning" @click="syncEntireDatabase">
        {{ $t('app.offline.selectSyncMethod.entireDatabase') }}
      </BButton>

      <BButton variant="primary" @click="syncNewDataOnly">
        {{ $t('app.offline.selectSyncMethod.newDataOnly') }}
      </BButton>
    </template>
  </BModal>
</template>

<script>
export default {
  methods: {
    showModal() {
      this.$refs['modal-for-selecting-sync-method'].show()
    },
    closeModal() {
      this.$refs['modal-for-selecting-sync-method'].hide()
    },
    syncEntireDatabase() {
      this.$root.$emit('forceSync', true)

      this.closeModal()
    },
    syncNewDataOnly() {
      this.$root.$emit('forceSync')

      this.closeModal()
    }
  }
}
</script>

<template>
  <div class="sidebar">
    <b-nav v-if="applications" class="mt-2" pills vertical>
      <b-nav-item v-b-tooltip.hover.right="'Bodygram'" :to="bodygramRoute">
        <img
          :src="`${$assetsBase}images/icons/fapps/bodygram.svg`"
          alt="Bodygram"
        />
      </b-nav-item>

      <b-nav-item
        v-if="
          applications.find(x => x.code === 'FIT').licensed ||
            isUsingOfflineDesktopApp
        "
        v-b-tooltip.hover.right="'Fitness App'"
        :to="fitnessRoute"
        :disabled="isUsingOfflineDesktopApp"
      >
        <img
          :src="`${$assetsBase}images/icons/fapps/fitness.svg`"
          :alt="$t('regional.name')"
        />
      </b-nav-item>
      <b-nav-item
        v-else
        v-b-tooltip.hover.right="'Fitness App'"
        style="opacity: 0.35"
        @click="openModalToRequestInfoAboutFitnessApp"
      >
        <img
          :src="`${$assetsBase}images/icons/fapps/fitness.svg`"
          :alt="$t('regional.name')"
        />
      </b-nav-item>

      <b-nav-item
        v-if="
          applications.find(x => x.code === 'REG' || x.code === 'RGN')
            .licensed || isUsingOfflineDesktopApp
        "
        v-b-tooltip.hover.right="'Regional App'"
        :to="regionalRoute"
        :disabled="isUsingOfflineDesktopApp"
      >
        <img
          :src="`${$assetsBase}images/icons/fapps/regional.svg`"
          alt="Regional App"
        />
      </b-nav-item>
      <b-nav-item
        v-else
        v-b-tooltip.hover.right="'Regional App'"
        style="opacity: 0.35"
        @click="openModalToRequestInfoAboutRegionalApp"
      >
        <img
          :src="`${$assetsBase}images/icons/fapps/regional.svg`"
          alt="Regional App"
        />
      </b-nav-item>

      <template v-if="isKetoAppExist">
        <!-- KETO -->
        <b-nav-item
          v-if="isKetoAppLicensed"
          v-b-tooltip.hover.right="'Keto App'"
          :disabled="isUsingOfflineDesktopApp"
          :style="!isUsingOfflineDesktopApp ? '' : 'opacity: 0.35'"
          :to="ketoRoute"
        >
          <img
            :src="`${$assetsBase}images/icons/fapps/keto.svg`"
            alt="Keto App"
          />
        </b-nav-item>
      </template>

      <template v-if="isPentaAppExist">
        <!-- PENTA -->
        <b-nav-item
          v-if="isPentaAppLicensed"
          v-b-tooltip.hover.right="'Penta App'"
          :disabled="isUsingOfflineDesktopApp"
          :style="!isUsingOfflineDesktopApp ? '' : 'opacity: 0.35'"
          :to="ketoRoute"
        >
          <img
            :src="`${$assetsBase}images/icons/fapps/penta.svg`"
            alt="Penta App"
          />
        </b-nav-item>
      </template>

      <template v-if="false">
        <b-nav-item disabled>
          <img :src="`${$assetsBase}images/icons/fapps/sport.svg`" alt="" />
        </b-nav-item>
      </template>

      <b-nav-item
        v-if="type !== 'shared'"
        v-b-tooltip.hover.right="'SIFA Dieta'"
        :disabled="isUsingOfflineDesktopApp"
        :style="isUsingOfflineDesktopApp ? '' : 'opacity: 0.35'"
        @click="openModalToRequestInfoAboutSifaApp"
      >
        <img
          :src="`${$assetsBase}images/icons/fapps/weight_control.png`"
          alt="SIFA Dieta"
        />
      </b-nav-item>
    </b-nav>

    <modal-to-request-info-about-fitness-app
      ref="modal-to-request-info-about-fitness-app"
    />
    <modal-to-request-info-about-sifa-app
      ref="modal-to-request-info-about-sifa-app"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ModalToRequestInfoAboutFitnessApp from '~/components/modals/ModalToRequestInfoAboutFitnessApp'
import ModalToRequestInfoAboutSifaApp from '~/components/modals/ModalToRequestInfoAboutSifaApp'

export default {
  components: {
    ModalToRequestInfoAboutFitnessApp,
    ModalToRequestInfoAboutSifaApp
  },
  computed: {
    ...mapGetters({
      accessToken: 'user/token',
      applications: 'applications/list',
      onLine: 'network/onLine',
      type: 'config/type'
    }),
    isKetoAppsHided() {
      return (
        process.env.platform !== 'web' ||
        process.env.releaseStage === 'production'
      )
    },
    isUsingOfflineDesktopApp() {
      return this.$platform === 'electron' && !this.onLine
    },
    isKetoAppExist() {
      return this.applications.find(app => app.code === 'KETA_TMP')
    },
    isPentaAppExist() {
      return this.applications.find(app => app.code === 'KETP_TMP')
    },
    isKetoAppLicensed() {
      const ketoApp = this.applications.find(app => app.code === 'KETA_TMP')

      if (ketoApp) {
        return ketoApp.licensed
      }

      return false
    },
    isPentaAppLicensed() {
      const pentaApp = this.applications.find(app => app.code === 'KETP_TMP')

      if (pentaApp) {
        return pentaApp.licensed
      }

      return false
    },
    bodygramRoute() {
      if (this.$route.name.includes('keto-')) {
        const currentRouteNameArr = this.$route.name.split('-')
        currentRouteNameArr.splice(0, 1)
        if (currentRouteNameArr.length > 4) {
          currentRouteNameArr.length = 4
          return this.localePath({
            name: currentRouteNameArr.join('-'),
            params: this.$route.params
          })
        } else {
          return {
            name: currentRouteNameArr.join('-'),
            params: this.$route.params
          }
        }
      } else {
        return this.localePath({
          name: 'index'
        })
      }
    },
    regionalRoute() {
      if (this.$route.name.includes('keto-')) {
        const currentRouteNameArr = this.$route.name.split('-')
        currentRouteNameArr.splice(0, 1)
        if (currentRouteNameArr.length > 4) {
          currentRouteNameArr.length = 4
          return this.localePath({
            name: 'regional-' + currentRouteNameArr.join('-'),
            params: this.$route.params
          })
        } else {
          return {
            name: 'regional-' + currentRouteNameArr.join('-'),
            params: this.$route.params
          }
        }
      } else {
        return this.localePath({
          name: 'regional'
        })
      }
    },
    fitnessRoute() {
      if (this.$route.name.includes('keto-')) {
        const currentRouteNameArr = this.$route.name.split('-')
        currentRouteNameArr.splice(0, 1)
        if (currentRouteNameArr.length > 4) {
          currentRouteNameArr.length = 4
          return this.localePath({
            name: 'fitness-' + currentRouteNameArr.join('-'),
            params: this.$route.params
          })
        } else {
          return {
            name: 'fitness-' + currentRouteNameArr.join('-'),
            params: this.$route.params
          }
        }
      } else {
        return this.localePath({
          name: 'fitness'
        })
      }
    }
  },
  methods: {
    openModalToRequestInfoAboutFitnessApp() {
      this.$refs['modal-to-request-info-about-fitness-app'].open()
    },
    openModalToRequestInfoAboutRegionalApp() {
      this.$refs['modal-to-request-info-about-regional-app'].open()
    },
    openModalToRequestInfoAboutSifaApp() {
      this.$refs['modal-to-request-info-about-sifa-app'].open()
    }
  }
}
</script>

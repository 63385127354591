export default function({ app, redirect, route, store, next }) {
  if (store.getters['user/user']) {
    const settingsLocale =
      store.getters['user/user'].locale === 'nl_BE'
        ? 'nl-be'
        : store.getters['user/user'].locale.slice(0, 2)

    const locationPath =
      typeof location.hash === 'string' && location.hash.length > 0
        ? location.hash.replace('#', '')
        : location.pathname

    if (
      app.i18n.locale !== settingsLocale &&
      route.path !== app.switchLocalePath(settingsLocale) &&
      locationPath !== app.switchLocalePath(settingsLocale)
    ) {
      redirect(app.switchLocalePath(settingsLocale))
      next(false)
    }
  }
}

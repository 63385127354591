import dayjs from 'dayjs'

const state = () => ({
  exams: null,
  deleted: null,
  exam: null,
  baseline: null,
  compartments: null
})

const getters = {
  exams: state => state.exams,
  deleted: state => state.deleted,
  exam: state => state.exam,
  baseline: state => state.baseline,
  compartments: state => state.compartments,
  selectedCompartment: state => {
    if (state.compartments) {
      const selectedCompartment = state.compartments.find(item => item.selected)
      return selectedCompartment ? selectedCompartment.name : null
    } else {
      return null
    }
  }
}

const mutations = {
  receiveExams(state, exams) {
    state.exams = exams
  },
  receiveDeleted(state, exams) {
    state.deleted = exams
  },
  receiveExam(state, exam) {
    state.exam = exam
  },
  receiveBaseline(state, exam) {
    state.baseline = exam
  },
  receiveCompartments(state, compartments) {
    state.compartments = compartments
  },
  toggleSelectedCompartment(state, itemId) {
    state.compartments.forEach(item => {
      if (item.id === itemId) {
        item.selected = !item.selected
      } else {
        item.selected = false
      }
    })
  }
}

const actions = {
  getExams({ commit, rootState }, patientId) {
    return this.$axios
      .post('/regional-app-backend/v1/app/exams/list', {
        accessToken: rootState.user.token,
        patientId: patientId
      })
      .then(response => {
        const exams = response.data.exams
        if (exams.length) {
          exams.forEach(exam => {
            exam.dateTime = dayjs(exam.effectiveAt).format('L LT')
          })
          // const baseline = exams.splice(
          //   exams.indexOf(exams.find(exam => exam.isBaseline)),
          //   1
          // )
          // exams.unshift(...baseline)
        }
        commit('receiveExams', exams)
      })
  },
  getDeleted({ commit, rootState }, patientId) {
    return this.$axios
      .post('/regional-app-backend/v1/app/exams/list', {
        accessToken: rootState.user.token,
        patientId: patientId,
        filter: {
          deleted: true
        }
      })
      .then(response => {
        const exams = response.data.exams
        if (exams.length) {
          exams.forEach(item => {
            item.dateTime = dayjs(item.createdAt).format('L LT')
          })
        }
        commit('receiveDeleted', exams)
      })
  },
  restoreExam({ dispatch, rootState, rootGetters }, examId) {
    return this.$axios.post('/regional-app-backend/v1/app/exams/restore', {
      accessToken: rootState.user.token,
      id: examId
    })
  },
  getExam({ commit, rootState }, examId) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post('/regional-app-backend/v1/app/exams/get', {
          accessToken: rootState.user.token,
          id: examId
        })
        .then(response => {
          const exam = response.data.exam
          this.$axios
            .post('/bodygram-backend/v1/core/patients/get', {
              accessToken: rootState.user.token,
              id: exam.patientId
            })
            .then(response => {
              exam.patient = response.data.patient
              commit('receiveExam', exam)
              const compartments = Object.keys(exam.compartments).map(
                (key, index) => {
                  return {
                    id: index,
                    name: key,
                    selected: false,
                    ...exam.compartments[key]
                  }
                }
              )
              commit('receiveCompartments', compartments)
              resolve(exam)
            })
            .catch(error => {
              reject(error)
            })
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getBaseline({ commit, rootState }, patientId) {
    return this.$axios
      .post('/regional-app-backend/v1/app/baseline/get', {
        accessToken: rootState.user.token,
        patientId: patientId
      })
      .then(response => {
        commit('receiveBaseline', response.data.exam)
      })
  },
  setBaseline({ dispatch, rootState }, exam) {
    return this.$axios
      .post('/regional-app-backend/v1/app/baseline/set', {
        accessToken: rootState.user.token,
        examId: exam.id
      })
      .then(() => {
        dispatch('getExams', exam.patientId)
      })
  },
  createExam({ dispatch, rootState, rootGetters }, exam) {
    return this.$axios
      .post('/regional-app-backend/v1/app/exams/create', {
        accessToken: rootState.user.token,
        exam: exam
      })
      .then(() => {
        dispatch('getExams', exam.patientId)
      })
  },
  updateExam({ dispatch, rootState, rootGetters }, exam) {
    return this.$axios
      .post('/regional-app-backend/v1/app/exams/update', {
        accessToken: rootState.user.token,
        id: exam.id,
        exam: exam
      })
      .then(() => {
        dispatch('getExam', exam.id)
      })
  },
  deleteExam({ rootState, rootGetters }, examId) {
    return this.$axios.post('/regional-app-backend/v1/app/exams/delete', {
      accessToken: rootState.user.token,
      id: examId
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}

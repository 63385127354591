<template>
  <div class="report-layout">
    <network-status v-if="$platform === 'electron'" />
    <sync v-if="$platform === 'electron' && type === 'standalone'" />
    <nuxt />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import NetworkStatus from '~/components/networkStatus'
import Sync from '~/components/sync'

export default {
  components: {
    NetworkStatus,
    Sync
  },
  middleware: async ({ store }) => {
    await store.dispatch('user/getSettings')
  },
  computed: {
    ...mapGetters({
      type: 'config/type',
      onLine: 'network/onLine',
      settings: 'user/settings',
      user: 'user/user'
    })
  },
  watch: {
    onLine() {
      this.$router.push(this.localePath({ name: 'index' }))
    }
  },
  async created() {
    if (!this.settings) {
      await this.getSettings()
    }

    if (
      this.$platform === 'electron' &&
      (this.settings.locale === 'nl_BE'
        ? 'nl-be'
        : this.settings.locale.slice(0, 2)) !== this.$i18n.locale
    ) {
      this.$router.push(
        this.switchLocalePath(
          this.settings.locale === 'nl_BE'
            ? 'nl-be'
            : this.settings.locale.slice(0, 2)
        )
      )
    }

    const locale = this.user ? this.user.locale.slice(0, 2) : 'en'
    this.$dayjs.locale(locale)
  },
  updated() {
    this.$dayjs.locale(this.$i18n.locale)
  },
  methods: {
    ...mapActions({
      getSettings: 'user/getSettings'
    })
  }
}
</script>

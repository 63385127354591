const state = () => ({
  sessions: null,
  regional: null
})
const getters = {
  sessions: state => state.sessions,
  regional: state => state.regional
}
const mutations = {
  receiveSessions(state, sessions) {
    state.sessions = sessions
  },
  receiveRegionalSessions(state, regionalSessions) {
    state.regional = regionalSessions
  }
}
const actions = {
  getSessions({ commit, rootState }) {
    return this.$axios
      .post('/bodygram-backend/v1/app/sessions/list', {
        accessToken: rootState.user.token
      })
      .then(response => {
        commit('receiveSessions', response.data.results)
      })
  },
  getRegionalSessions({ commit, rootState }) {
    return this.$axios
      .post('/regional-app-backend/v1/app/sessions/list', {
        accessToken: rootState.user.token
      })
      .then(response => {
        commit('receiveRegionalSessions', response.data.results)
      })
  }
}
export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}

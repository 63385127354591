import Vue from 'vue'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/de'
import 'dayjs/locale/en'
import 'dayjs/locale/it'
import 'dayjs/locale/nl'
import 'dayjs/locale/nl-be'
import 'dayjs/locale/es'
import 'dayjs/locale/fr'
import 'dayjs/locale/pl'
import 'dayjs/locale/zh'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(LocalizedFormat)
dayjs.extend(isSameOrAfter)

Vue.prototype.$dayjs = dayjs

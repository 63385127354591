const state = () => ({
  profile: null,
  patientId: null,
  exam: null,
  exams: null,
  isBaseline: false
})

const getters = {
  profile: state => state.profile,
  exam: state => {
    const exam = { ...state.exam }
    exam.percentage = 0
    let steps = []
    if (exam.isBaseline) {
      steps = ['bia', 'skinfold', 'circumference', 'cardio', 'fitness']
    } else if (
      state.profile &&
      state.profile.goal &&
      state.profile.goal.protocol
    ) {
      steps = state.profile.goal.protocol
    }
    steps.forEach(step => {
      if (exam[step + 'Id']) {
        exam.percentage += 100 / steps.length
      }
    })
    return exam
  },
  exams: state => state.exams,
  baseline: state => {
    if (state.profile && state.profile.exam) {
      const baseline = { ...state.profile.exam }
      baseline.percentage = 0
      const steps = ['bia', 'skinfold', 'circumference', 'cardio', 'fitness']
      steps.forEach(step => {
        if (baseline[step + 'Id']) {
          baseline.percentage += 20
        }
      })
      return baseline
    } else {
      return null
    }
  },
  goal: state => (state.profile ? state.profile.goal : null),
  wizard: state => {
    if (state.exam) {
      if (state.isBaseline) {
        return ['bia', 'skinfold', 'circumference', 'cardio', 'fitness']
      } else if (state.profile && state.profile.goal) {
        return state.profile.goal.protocol
      }
    }
    return null
  },
  modalIds: state => {
    return {
      bia:
        state.exam && state.exam.biaId ? 'bia-' + state.exam.biaId : 'bia-add',
      skinfold:
        state.exam && state.exam.skinfoldId
          ? 'skinfold-' + state.exam.skinfoldId
          : 'skinfold-add',
      circumference:
        state.exam && state.exam.circumferenceId
          ? 'circumference-' + state.exam.circumferenceId
          : 'circumference-add',
      cardio:
        state.exam && state.exam.cardioId
          ? 'cardio-' + state.exam.cardioId
          : 'cardio-add',
      training:
        state.exam && state.exam.trainingId
          ? 'training-' + state.exam.trainingId
          : 'training-add',
      fitness:
        state.exam && state.exam.fitnessId
          ? 'fitness-' + state.exam.fitnessId
          : 'fitness-add'
    }
  }
}

const mutations = {
  receiveProfile(state, profile) {
    state.profile = profile
  },
  receivePatientId(state, patientId) {
    state.patientId = patientId
  },
  setExam(state) {
    state.isBaseline = false
    state.exam = {
      isBaseline: false,
      patientId: state.patientId
    }
    if (state.profile && state.profile.goal && state.profile.goal.protocol) {
      state.profile.goal.protocol.forEach(item => {
        state.exam[item + 'Id'] = null
      })
    }
  },
  resetExam(state) {
    state.isBaseline = false
    state.exam = null
  },
  receiveExam(state, exam) {
    state.exam = exam
    state.isBaseline = exam.isBaseline
  },
  receiveBaseline(state) {
    state.isBaseline = true
    if (state.profile && state.profile.exam) {
      state.exam = state.profile.exam
    } else {
      state.exam = {
        patientId: state.patientId,
        biaId: null,
        skinfoldId: null,
        circumferenceId: null,
        cardioId: null,
        fitnessId: null,
        isBaseline: true
      }
    }
  },
  receiveExams(state, exams) {
    state.exams = exams
  }
}

const actions = {
  getExam({ commit, rootState }, examId) {
    return this.$axios
      .post('/fitness-app-backend/v1/app/exams/get', {
        accessToken: rootState.user.token,
        id: examId
      })
      .then(response => {
        const exam = response.data.exam
        commit('receiveExam', exam)
      })
  },
  getExams({ commit, state, rootState }, patientId) {
    return this.$axios
      .post('/fitness-app-backend/v1/app/exams/list', {
        accessToken: rootState.user.token,
        patientId: patientId
      })
      .then(response => {
        const exams = response.data.exams.filter(item => !item.isBaseline)
        if (
          state.profile &&
          state.profile.goal &&
          state.profile.goal.protocol
        ) {
          exams.forEach(exam => {
            exam.percentage = 0
            state.profile.goal.protocol.forEach(step => {
              if (exam[step + 'Id']) {
                exam.percentage += 100 / state.profile.goal.protocol.length
              }
            })
          })
        }
        commit('receiveExams', exams)
      })
  },
  getProfile({ commit, rootState }, patientId) {
    return this.$axios
      .post('/fitness-app-backend/v1/app/profile/get', {
        accessToken: rootState.user.token,
        id: patientId
      })
      .then(response => {
        commit('receiveProfile', response.data.profile)
        commit('receivePatientId', patientId)
      })
  },
  setBaselineInProfile({ dispatch, state, rootState }, baselineExamId) {
    return this.$axios
      .post('/fitness-app-backend/v1/app/profile/set', {
        accessToken: rootState.user.token,
        profile: {
          examId: baselineExamId,
          patientId: state.patientId
        }
      })
      .then(response => {
        dispatch('getProfile', state.patientId).then(() => {
          dispatch('getExams', state.patientId)
        })
        return response
      })
  },
  setGoalInProfile({ dispatch, state, rootState }, goalId) {
    return this.$axios
      .post('/fitness-app-backend/v1/app/profile/set', {
        accessToken: rootState.user.token,
        profile: {
          goalId: goalId,
          patientId: state.patientId
        }
      })
      .then(response => {
        dispatch('getProfile', state.patientId).then(() => {
          dispatch('getExams', state.patientId)
        })
        return response
      })
  },
  createExam({ commit, dispatch, state, rootState }, biaId) {
    const exam = {
      ...state.exam
    }

    if (typeof biaId === 'number') {
      exam.biaId = biaId
    } else if (
      typeof biaId === 'object' &&
      biaId &&
      biaId.biaId &&
      biaId.effectiveAt
    ) {
      exam.biaId = biaId.biaId
      exam.effectiveAt = biaId.effectiveAt
    }

    return this.$axios
      .post('/fitness-app-backend/v1/app/exams/create', {
        accessToken: rootState.user.token,
        exam
      })
      .then(response => {
        commit('receiveExam', {
          ...response.data.exam,
          isBaseline: state.isBaseline
        })
        if (state.isBaseline) {
          dispatch('setBaselineInProfile', response.data.exam.id)
        } else {
          dispatch('getExams', state.patientId)
        }
        return response
      })
  },
  updateExam({ commit, dispatch, state, rootState }, data) {
    // data: { type: 'skinfold', id: 14 }
    const exam = { ...state.exam }
    exam[data.type + 'Id'] = data.id
    delete exam.isBaseline
    return this.$axios
      .post('/fitness-app-backend/v1/app/exams/update', {
        accessToken: rootState.user.token,
        exam: exam
      })
      .then(response => {
        commit('receiveExam', response.data.exam)
        if (!state.isBaseline) {
          dispatch('getExams', state.patientId)
        }
        return response
      })
  },
  deleteExam({ rootState }, examId) {
    return this.$axios.post('/fitness-app-backend/v1/app/exams/delete', {
      accessToken: rootState.user.token,
      id: examId
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}

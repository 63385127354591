<template><div></div></template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      onLine: 'network/onLine',
      type: 'config/type'
    })
  },
  watch: {
    onLine(v) {
      if (v) {
        this.updateStatus('backToOnLine')
      }
    },
    type(type) {
      if (type !== 'shared') {
        window.addEventListener('online', this.updateOnlineStatus)
        window.addEventListener('offline', this.updateOnlineStatus)
      } else {
        window.removeEventListener('online', this.updateOnlineStatus)
        window.removeEventListener('offline', this.updateOnlineStatus)
      }
    }
  },
  created() {
    if (this.$store.getters['config/type'] !== 'shared') {
      this.setStatus(navigator.onLine)
    }
  },
  mounted() {
    if (this.$store.getters['config/type'] !== 'shared') {
      window.addEventListener('online', this.updateOnlineStatus)
      window.addEventListener('offline', this.updateOnlineStatus)
    }
  },
  beforeDestroy() {
    if (this.$store.getters['config/type'] !== 'shared') {
      window.removeEventListener('online', this.updateOnlineStatus)
      window.removeEventListener('offline', this.updateOnlineStatus)
    }
  },
  methods: {
    ...mapMutations({
      setStatus: 'network/setStatus'
    }),
    ...mapActions({
      updateStatus: 'network/updateStatus'
    }),
    updateOnlineStatus(e) {
      // set onLine status in store
      const { type } = e
      this.setStatus(type === 'online')
    }
  }
}
</script>

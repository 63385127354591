<template>
  <div class="container-fluid mt-3">
    <b-breadcrumb
      :class="$route.name.includes('reports-') ? 'breadcrumb-resized' : ''"
      :items="items"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      exam: 'regional/exam',
      group: 'group/group',
      patient: 'patient/patient',
      crumb: 'crumb/crumb'
    }),
    items() {
      const crumbs = [
        {
          html: '<i class="fas fa-home"></i>',
          to: this.localePath('regional')
        }
      ]
      if (this.$route.name.includes('schedule')) {
        crumbs.push({
          text: this.$t('app.schedule.schedule'),
          to: this.localePath('regional-schedule')
        })
      } else if (this.$route.name.includes('search')) {
        crumbs.push({
          text: this.$t('app.common.search'),
          to: this.localePath('regional-search')
        })
      } else {
        crumbs.push({
          text: this.$t('app.folders.folders'),
          to: this.localePath('regional-groups')
        })
      }
      if (this.$route.params.groupId) {
        crumbs.push({
          text: this.group ? this.group.name : '',
          to: this.localePath(`/regional/groups/${this.$route.params.groupId}`)
        })
      }
      if (this.$route.params.patientId) {
        crumbs.push({
          text: this.patient
            ? this.patient.firstName + ' ' + this.patient.lastName
            : '',
          to: this.localePath(
            `/regional/groups/${this.$route.params.groupId}/patients/${this.$route.params.patientId}`
          )
        })
      }
      if (this.$route.params.examId) {
        crumbs.push({
          text: this.exam
            ? `${this.$t('app.common.testOf')} ${this.getDateTime(
                this.exam.createdAt
              )}`
            : '',
          to: this.localePath(
            `/regional/groups/${this.$route.params.groupId}/patients/${this.$route.params.patientId}/exams/${this.$route.params.examId}`
          )
        })
      }
      if (this.$route.name.includes('reports')) {
        crumbs.push({
          text: this.$t('app.reports.reports'),
          to: this.localePath({
            name:
              'regional-groups-groupId-patients-patientId-exams-examId-reports',
            params: {
              groupId: this.$route.params.groupId,
              patientId: this.$route.params.patientId,
              examId: this.$route.params.examId
            }
          })
        })
      }
      if (this.$route.name.includes('attachments')) {
        crumbs.push({
          text: this.$t('app.common.attachments'),
          to: this.localePath({
            name:
              'regional-groups-groupId-patients-patientId-histories-historyId-attachments',
            params: {
              groupId: this.$route.params.groupId,
              patientId: this.$route.params.patientId,
              historyId: this.$route.params.historyId
            }
          })
        })
      }
      if (this.crumb) {
        crumbs.push({
          text: this.crumb
        })
      }
      return crumbs
    }
  },
  created() {
    if (this.$route.params.examId && !this.exam) {
      this.getExam(this.$route.params.examId)
    }
    if (this.$route.params.groupId && !this.group) {
      this.getGroup(this.$route.params.groupId)
    }
    if (this.$route.params.patientId && !this.patient) {
      this.getPatient(this.$route.params.patientId)
    }
  },
  methods: {
    ...mapActions({
      getExam: 'regional/getExam',
      getGroup: 'group/getGroup',
      getPatient: 'patient/getPatient'
    }),
    getDateTime(date) {
      return this.$dayjs(date).format('L LT')
    }
  }
}
</script>
